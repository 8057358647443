﻿import { Component, OnDestroy } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { StorageService } from '../../providers/storage.service';
import { ChartService } from '../../providers/chart.service';
import { FusionChartsService } from '../../providers/fusion-charts.service';
import { Router, ActivatedRoute } from "@angular/router";
import { debounceTime, finalize } from 'rxjs/operators';
import { DataService } from '../../providers/data.service';
import { AuthService } from 'app/providers/auth.service';


@Component({
  moduleId: module.id,
  selector: 'page-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class PageDashboardComponent implements OnDestroy
{
  title: string;
  gs = GlobalService;
  subscription: any;
  ajaxSubscription: any;
  user: any;
  organogramIds: any = [];
  currentOrganogramId;
  config: any = { type: 'mscolumn2d', id: 'managerIndex', title: 'Manager Index', service: "manager-index", chartOptions: { showPercentValues: 1, showLegend: 0, decimals: 0 } };
  breadCrumb;
  colWidth = 0;
  parameters: any;
  round: any = Math.round;
  toInt: any = parseInt;
  initialLoad: any = true;
  pageInfo: any = [{ id: "manager-index", Title: "Manager Index By Teams" }, { id: "employee-engagement", Title: "Employee Commitment" },
    { id: "adcb-values", Title: "ADCB Values" }, { id: "clear-and-promising-direction", Title: "Clear and Promising Direction" },
    { id: "confidence-in-leaders", Title: "Confidence in Leaders" },
    { id: "quality-and-customer-focus", Title: "Quality and Customer Focus" }, { id: "work-structure-and-process", Title: "Work Structure and Process" },
    { id: "employee-enablement", Title: "Employee Enablement" }, { id: "collaboration", Title: "Collaboration" }, { id: "resources", Title: "Resources" },
    { id: "respect-and-recognition", Title: "Respect and Recognition" }, { id: "authority-and-empowerment", Title: "Authority and Empowerment" }, { id: "development-opportunities", Title: "Development Opportunities" },
    { id: "performance-management", Title: "Performance Management" }, { id: "pay-and-benefits", Title: "Pay and Benefits" },
    { id: "action-planning-activity", Title: "Action Planning Activity" },
    { id: "enps", Title: "eNPS" }, { id: "pnps", Title: "pNPS" }];
  pageUrl: any;

  //colorArr = [{ Name: "2H 18", Color: "#969393" }, { Name: "1H 19", Color: "#007681" }, { Name: "2H 19", Color: "#959aa4" }, { Name: "2H 20", Color: "#2a2c31" }, { Name: "1H 21", Color: "#c00000" }]
  //colorArr = [{ Name: "H2 18", Color: "#969393" }, { Name: "H1 19", Color: "#007681" }, { Name: "H2 20", Color: "#959aa4" }, { Name: "H1 21", Color: "#959aa4" }, { Name: "H2 21", Color: "#959aa4" }, { Name: "H1 22", Color: "#2a2c31" }, { Name: "H2 22", Color: "#c00000" }]
  colorArr = [{ Name: "H2 18", Color: "#969393" }, { Name: "H1 19", Color: "#007681" }, { Name: "H2 20", Color: "#959aa4" }, { Name: "H1 21", Color: "#959aa4" }, { Name: "H2 21", Color: "#959aa4" }, { Name: "H1 22", Color: "#959aa4" }, { Name: "H2 22", Color: "#2a2c31" }, { Name: "H1 23", Color: "#959aa4" }, { Name: "H2 23", Color: "#2a2c31" }, { Name: "H1 24", Color: "#c00000" }]
//colorArr = [{ Name: "H2 18", Color: "#969393" }, { Name: "H1 19", Color: "#007681" }, { Name: "H2 20", Color: "#959aa4" }, { Name: "H1 21", Color: "#959aa4" }, { Name: "H2 21", Color: "#2a2c31" }, { Name: "H1 22", Color: "#c00000" }]
  //colorArr = [{ Name: "H2 18", Color: "#969393" }, { Name: "H1 19", Color: "#007681" }, { Name: "H2 20", Color: "#959aa4" }, { Name: "H1 21", Color: "#2a2c31" }, { Name: "H2 21", Color: "#c00000" }]
  constructor(private chartService: ChartService, private router: Router, private authService: AuthService, private route: ActivatedRoute)
  {
    
    this.route.params.subscribe(params =>
    {
      this.user = StorageService.get('user');
      this.organogramIds = this.user.OrganogramIds;
      this.breadCrumb = StorageService.get("breadCrumb") || [{ Id: "1", Name: "ADCB Egypt" }];

      let id = params["id"] ? params["id"] : "1";
      this.title = this.pageInfo.filter(x => x.id == id)[0]["Title"];
      GlobalService.changeTitle(this.title);
      if (!this.initialLoad || GlobalService.filters.length>0)
        this.run();
    });
    //this.title = "Manager Index Survey Results";

    
    this.subscription = GlobalService.filtersObservable.subscribe(filters =>
    {      
      this.run();
    });
    this.subscription.add(GlobalService.bankObservable.subscribe(bank =>
    {
      this.breadCrumb = [{ Id: "1", Name: "ADCB Egypt" }];
      StorageService.set("breadCrumb", this.breadCrumb);
      this.run();
    }));

    
  }

  ngOnInit()
  {
    FusionChartsService.clickObservable.subscribe((obj: any) =>
    {
      if (obj.stubId == this.breadCrumb[this.breadCrumb.length - 1].Id)
        return;
      let user = this.authService.getUser();
      if (!user.OrganogramIds.find(x => x == parseInt(obj.stubId)))
        return;
      let stub = this.config.table.Stubs.find(x => x.Id == obj.stubId);
      this.breadCrumb.push({ Id: obj.stubId, Name: stub.Name });
      StorageService.set("breadCrumb", this.breadCrumb);
      this.run();
    });
  }

  public attrData()
  {
    let args = this.breadCrumb[this.breadCrumb.length - 1].Id;
    this.router.navigate(['dashboard-attr/' + this.title + "/" + args]);
  }

  public run()
  {     
    this.initialLoad = false;
    var filters = GlobalService.serializeFilters();
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();

    if (GlobalService.bank == 'ahb')
      filters += ';Bank=4';

    this.config.filters = filters;
    this.config.loading = true;
    this.config.args = "organogram:" + this.breadCrumb[this.breadCrumb.length - 1].Id;
    this.config.pageTitle = this.title;
    this.config.fc = null;
    if (this.title == "Manager Index By Teams")
    {
      this.config.fc =
        {
          "trendlines": [
            {
              "line": [
                {
                  "startvalue": "75",
                  "endvalue": "",
                  "color": "#5f6569",
                  "displayvalue": "Ambition <br/> Target:<br/> MI >75%",
                  "valueonright": "0",
                  "dashed": "1",
                  "thickness": "1"
                }
              ]
            }]
        }
    }
    this.ajaxSubscription = this.chartService.getTable(this.config).pipe(debounceTime(500),
      finalize(() =>
      {
        this.config.loading = false;
      }))
      .subscribe((tables) =>
      {
        let table = tables[0];
        for (var i = 0; i < table.Banners.length; i++)
        {
          var banner = table.Banners[i];
          for (var j = 0; j < banner.Values.length; j++)
          {
            if (banner.Values[j].BaseTotal < 5)
              banner.Values[j].Perc = 0;
          }
        }

        let skip = false;
        if (table.Stubs.length == 1)
        {
          let count = 0;
          for (var i = 0; i < table.Banners.length; i++)
          {
            if (table.Banners[i].Values[0].Perc == table.Banners[i].Values[1].Perc && table.Banners[i].Values[0].BaseTotal == table.Banners[i].Values[1].BaseTotal)
              count += 1;
          }
          if (count == table.Banners.length)
            skip = true;
          else if (table.Stubs[0].Name == table.Stubs[1].Name)
          {
            skip = true;
          }
        }
        if (skip)
        {
          this.breadCrumb.pop();
          return;
        }

        this.config.table = tables[0];
        this.config.table1 = tables[1];
        
        this.config.table.Banners.forEach(x =>
        {
          let allZero = true;
          x.Values.forEach(y =>
          {
            if (parseFloat(y.Perc) > 0)
              allZero = false;
          });
          if (allZero)
            x.Hide = true;
          else
            x.Hide = false;
        });

        if (this.config.table.Banners.filter(x => !x.Hide).length == 0)
          this.config.table.Hide = true;

        this.config.table1.Banners.forEach(x =>
        {
          let allZero = true;
          x.Values.forEach(y =>
          {
            if (parseFloat(y.Perc) > 0)
              allZero = false;
          });
          if (allZero)
            x.Hide = true;
          else
            x.Hide = false;
        });

        if (this.config.table1.Banners.filter(x => !x.Hide).length == 0)
          this.config.table1.Hide = true;

        if (table)
        {
          this.config.chartOptions.chartLeftMargin = (document.querySelector('.breadcrumb').clientWidth / (table.Stubs.length + 1)) - 35;          
          table.Banners.forEach((x, i) =>
          {
            x.Color = this.colorArr.filter(y => y.Name == x.Name)[0]["Color"];
            //x.Color = ChartService.colors[i]
          });
          //if (table.Banners.length == 1)
          //{
          //  table.Banners[0].Color = table.Banners[0].Id == "1" ? '#969393' : '#ba1b19';
          //}
        }
        this.config.tableForExport = DataService.transposeTable(table);
        FusionChartsService.renderChartFromTable(this.config);
      },
        (err) =>
        {
          console.log(err);
        });
  }

  getTableWidth(n)
  {

    if (n <= 4)
      return '50%';
    else
      return (n * 13) + '%';
  }

  back(index)
  {
    this.breadCrumb.splice(index + 1);
    StorageService.set("breadCrumb", this.breadCrumb);
    this.run();
  }

  ngOnDestroy()
  {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
}
